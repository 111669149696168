// @flow
import React from "react";
import rules from "./input.rules";
import { Input, Form } from "antd";
import { ColProps } from "antd/lib/col";
const FormItem = Form.Item;

type Props = {
	name: string,
	defaultValue?: string | number,
	className?: string,
	type?: string,
	label?: string,
	style?: React.CSSProperties,
	required?: boolean,
	getFieldDecorator?: Function,
	disabled?: boolean,
	placeholder?: string,
	size?: "small" | "middle" | "large",
	rules?: any,
	validatorMessage?: string,
	onPressEnter?: Function,
	prefix?: any,
	suffix?: any,
	horizontalLayout?: boolean,
	labelCol?: ColProps,
	wrapperCol?: ColProps,
	normalize?: any,
	onChange?: any,
    onPaste?: Function,
    autoComplete?: String,
};

function CustomInput(props: Props) {
	const {
		required,
		defaultValue,
		getFieldDecorator,
		label,
		name,
		type,
		disabled,
		style,
		className,
		placeholder,
		size,
		rules,
		validatorMessage,
		onPressEnter,
		prefix,
		suffix,
		horizontalLayout,
		labelCol,
		wrapperCol,
		normalize,
		onChange,
        onPaste,
        autoComplete
	} = props;

	const crules = [
		{
			required,
			message: validatorMessage
		}
	];
	let layout = horizontalLayout
		? {
				labelCol: labelCol || {
					span: 10,
					style: { textAlign: "left" }
				},
				wrapperCol: wrapperCol || { span: 14 }
		  }
		: {};
	if (rules) crules.push(rules);
	return (
		<FormItem {...layout} label={label}>
			{!getFieldDecorator ||
				getFieldDecorator(name, {
					normalize: normalize,
					rules: crules,
					initialValue: defaultValue
				})(
					<Input
						suffix={suffix}
						size={size}
						style={style}
						placeholder={placeholder}
						className={className}
						type={type}
						prefix={prefix}
						disabled={disabled}
						onChange={onChange}
                        onPaste={onPaste}
                        autoComplete={autoComplete}
						onPressEnter={onPressEnter}
					/>
				)}
		</FormItem>
	);
}
CustomInput.rules = rules;
CustomInput.defaultProps = {
	size: "default",
	onPressEnter: null,
	required: false,
	type: "text",
	getFieldDecorator: null,
	disabled: false,
	horizontalLayout: false,
	validatorMessage: "This field is required"
};
export default CustomInput;
