import { actionFailed, actionRequest, actionSuccess } from "../helper";
import { actions as types } from "./action";

export type State = {
  jobs: any | null;
  pagination: {
    pageNo: number;
    pageSize: number;
    total: number | null;
  };
};

const initState: State = {
  jobs: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
    total: null,
  },
};

export default function Reducer(state = initState, action: any) {
  switch (action.type) {
    case actionRequest(types.FETCH_ALL_JOBS): {
      return {
        ...state,
        jobs: null,
      };
    }

    case actionSuccess(types.FETCH_ALL_JOBS): {
      return {
        ...state,
        jobs: action.payload.Jobs,
      };
    }

    case actionFailed(types.FETCH_ALL_JOBS): {
      return {
        ...state,
        jobs: null,
      };
    }

    case actionRequest(types.ADD_NEW_JOB): {
      return state;
    }

    case actionSuccess(types.ADD_NEW_JOB): {
      return state;
    }
    
    case actionFailed(types.ADD_NEW_JOB): {
      return state;
    }

    default: {
      return state;
    }
  }
}
