import { actions as types, getCurrentKey } from "./action";

const initState = {
	modal: null,
	modalEdited: false,
	sidebar: {
		isCollapsed: window.innerWidth > 2600 ? false : true,
		currentKey: getCurrentKey(),
		theme: "sidebar-theme--dark",
	},
};

export default function appReducer(state = initState, action) {
	switch (action.type) {
		case types.OPEN_MODAL: {
			return { ...state, modal: action.payload, modalEdited: false };
		}
		case types.CLOSE_MODAL: {
			return { ...state, modal: null, modalEdited: false };
		}
		case types.COLLPSE_CHANGE: {
			let sidebar = { ...state.sidebar };
			sidebar.isCollapsed = !sidebar.isCollapsed;
			return { ...state, sidebar: sidebar };
		}
		case types.CHANGE_CURRENT: {
			let sidebar = { ...state.sidebar };
			sidebar.currentKey = action.current;
			return { ...state, sidebar: sidebar };
		}
		default:
			return state;
	}
	return state;
}
