// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import { toggleCollapsed, changeCurrent } from "../../redux/App/action";
import Logo from "../../components/utility/logo";
import { logOutRequest } from "../../redux/Authentication/action";
import { Scrollbars } from "react-custom-scrollbars";
import Space from "../../components/uielements/space";

const { Sider } = Layout;

class Sidebar extends React.Component {
  state = {};
  handleClick = (e) => {
    this.props.changeCurrent(e.key);
    if (e.key === "dashboard.logout") {
      this.props.logOutRequest();
      return;
    }
  };

  render() {
    const { sidebar } = this.props.app;
    const { url } = this.props;
    return (
      <Sider
        theme="dark"
        collapsedWidth={60}
        className={`sidebar-container ${sidebar.theme}`}
        collapsed={sidebar.isCollapsed}
        onCollapse={() => this.props.toggleCollapsed()}
      >
        <div className="sidebar-top">
          <Logo collapsed={sidebar.isCollapsed} />
        </div>
        <Scrollbars autoHide style={{ height: "85%" }}>
          <Menu
            theme="dark"
            onClick={this.handleClick}
            className="sidebar-menu"
            defaultSelectedKeys={[sidebar.currentKey]}
            mode="inline"
          >
            <Menu.Item key="dashboard.jobs">
              <Link className="sidebar-menu__item" to={`${url}`}>
                <span className="sidebar-menu__item">
                  <Icon type="bars" />
                  <span>Jobs</span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="dashboard.logout">
              <span className="sidebar-menu__item">
                <Icon type="logout" />
                <span>Logout</span>
              </span>
            </Menu.Item>
           
          </Menu>          
          <Space h={400} />
        </Scrollbars>
      </Sider>
    );
  }
}
const mapStateToProps = (state) => {
  const { App } = state;
  return {
    app: App,
  };
};
const mapDispatchToProps = (dispatch) => ({
  toggleCollapsed: () => dispatch(toggleCollapsed()),
  changeCurrent: (k) => dispatch(changeCurrent(k)),
  logOutRequest: () => dispatch(logOutRequest()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
