import { parseInt } from "lodash";
import FileSaver from "file-saver";
import { getFileNameFromAttachment } from "./helpers";

class Error {
	status = Number();
	type = String();
	message = String();
	description = String();
	payload = Object();
}

export const statusMessages = {
	400: {
		status: 400,
		type: "BAD_REQUEST",
		message: "Bad Request!",
	},
	401: {
		status: 401,
		type: "UNAUTHORIZED_ACCESS",
		message: "Unauthorized Access!",
	},
	403: {
		status: 403,
		type: "FORBIDDEN_ACCESS",
		message: "Forbidden Access!",
	},
	422: {
		status: 422,
		type: "VALIDATION_ERROR",
		message: "Validation Error!",
	},
	440: {
		status: 440,
		type: "SESSION_EXPIRED",
		message: "Session Expired!",
	},
};

export let checkStatus = (response) => {
	let error = new Error();
	let statusCode = parseInt(response.status);
	if (statusCode === 200) {
		return response.json();
	}
    //Internal Server Error Temporary Fix
    if(statusCode === 500)
    {
        return response.json();
    }

	if ([401, 403, 440].indexOf(statusCode) >= 0) {
		error.message = statusMessages[`${statusCode}`].message;
		error.type = statusMessages[`${statusCode}`].type;
		error.description = "";
		error.status = statusCode;
		error.payload = {};
		throw error;
	}

	if (statusCode === 400) {
		return response.json().then((err) => {
			if (err && err.Code && err.Code === -1) {
				error.status = 422;
				error.message = statusMessages["422"].message;
				error.type = statusMessages["422"].type;
				error.payload = err;
				throw error;
			} else {
				error.status = 400;
				error.message = statusMessages["400"].message;
				error.type = statusMessages["400"].type;
				error.payload = err;
				throw error;
			}
		});
	} else {
		error.status = 400;
		error.message = statusMessages["400"].message;
		error.type = statusMessages["400"].type;
		throw error;
	}
};

export let checkResponseCode = (response) => {
	if (response.Code === 0) {
		return response;
	} else {
		const error = new Error(response.Message);
        //Temporary fix
        if(response.Errors)
        {
            error.status = 500 //Internal Server Error
        }
		error.payload = response;
		throw error;
	}
};

export const fileDownloadHelper = (response) => {
	let statusCode = parseInt(response.status);
	if (statusCode >= 200 && statusCode < 300) {
		return response
			.blob()
			.then((blob) =>
				FileSaver.saveAs(
					blob,
					getFileNameFromAttachment(
						response.headers.get("Content-Disposition")
					)
				)
			);
	} else {
		return response.json().then((err) => {
			throw err;
		});
	}
};
