import React from 'react';
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import DashApp from './dashApp';

ReactDOM.render(
  <ConfigProvider locale={enUS}>
    <DashApp />
  </ConfigProvider>,
  document.getElementById("root")
);
