export const actions = {
	SHOW_SPINNER: "SHOW_SPINNER",
	HIDE_SPINNER: "HIDE_SPINNER",

	SHOW_ERROR: "SHOW_ERROR",
	REMOVE_ERRORS: "REMOVE_ERRORS",

	ADD_DONE_ACTIONS: "ADD_DONE_ACTIONS",
	REMOVE_DONE_ACTIONS: "REMOVE_DONE_ACTIONS",
};

export const showSpinner = context => ({
	type: actions.SHOW_SPINNER,
	payload: { context }
});

export const hideSpinner = context => ({
	type: actions.HIDE_SPINNER,
	payload: context
});

export const showError = (
	errType,
	message,
	description = "",
	context = "global",
	payload = null
) => ({
	type: actions.SHOW_ERROR,
	payload: { errType, message, description, context, payload }
});

export const removeErrors = idxs => ({
	type: actions.REMOVE_ERRORS,
	payload: idxs
});

export const addDoneActions = (action, payload = null) => ({
	type: actions.ADD_DONE_ACTIONS,
	payload: { action, payload }
});

export const removeDoneActions = indexes => ({
	type: actions.REMOVE_DONE_ACTIONS,
	payload: indexes
});

export default actions;
