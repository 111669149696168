import { lowerCase } from "lodash";

export const actions = {
	OPEN_MODAL: "OPEN_MODAL",
	CLOSE_MODAL: "CLOSE_MODAL",
	COLLPSE_CHANGE: "COLLPSE_CHANGE",
	CHANGE_CURRENT: "CHANGE_CURRENT",
};

export const getCurrentKey = () => {
	let pathArray = window.location.pathname.split("/");
	switch (lowerCase(pathArray[2])) {
		case "": {
			return "dashboard.home";
		}
		case "applications": {
			return "dashboard.home";
		}
		default: {
			return "dashboard." + lowerCase(pathArray[2]);
		}
	}
};

export const openModal = (name, props) => ({
	type: actions.OPEN_MODAL,
	payload: {
		modalName: name,
		modalProps: props
	},
});

export const closeModal = () => ({
	type: actions.CLOSE_MODAL,
});

export const toggleCollapsed = (windowSize) => {
	return {
		type: actions.COLLPSE_CHANGE,
	};
};

export const changeCurrent = (current) => {
	return {
		type: actions.CHANGE_CURRENT,
		current,
	};
};