import auth from "../../utils/authenticator";
import { actions as types } from "./action";
const initState = {
	isAuthenticated: auth.isLoggedIn(),
	loggedInUser: null
};

export default function sessionReducer(state = initState, action) {
	switch (action.type) {
		case types.LOG_IN_SUCCESS: {
			return {
				...state,
				isAuthenticated: true,
			};
		}
		// case types.FETCH_LOGGED_IN_AGENT_REQUEST: {
		//   return { ...state, loggedInUser: null };
		// }
		case types.FETCH_LOGGED_IN_AGENT_SUCCESS: {
			let jsonString = atob(action.payload.Data);
			let user = JSON.parse(jsonString);
			
			if (user.Roles.length > 0) {
				user.Role = user.Roles[0];
			}
			if (user.Agencies.length > 0) {
				user.Agency = user.Agencies[0];
			}
			return { ...state, loggedInUser: user };
		}
		case types.LOG_OUT_SUCCESS: {
			auth.logOut();
			return { ...state, isAuthenticated: false };
		}
		case types.LOG_OUT_FAILED: {
			auth.logOut();
			return { ...state, isAuthenticated: false };
		}
		case types.UNAUTHORIZED_ACCESS: {
			return { ...state };
		}
		case types.SESSION_EXPIRED: {
			auth.logOut();
			return { ...state, isAuthenticated: false };
		}
		default:
			return state;
	}
}
