interface Option {
    value: string;
    label: string;
    children?: Option[];
  }
  
export const options: Option[] = [
    {
      value: "Minute",
      label: "Minute",
    },
    {
      value: "Hour",
      label: "Hour",
    },
    {
      value: "Day",
      label: "Day",
    },

    {
      value: "Month",
      label: "Month",
    },
  ];

 export  const checkoptions = [
    { label: "S", value: 0 },
    { label: "M", value: 2 },
    { label: "T", value: 3 },
    { label: "W", value: 4 },
    { label: "T", value: 5 },
    { label: "F", value: 6 },
    { label: "S", value: 7 },
  ];