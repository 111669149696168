
import { actions as types } from "./action";
import service from "../../services/JobScheduler";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";

const getAllJobs = getMiddleware({
    actionType: types.FETCH_ALL_JOBS,
    onRequest: apiRequestHandler(service.getAllJobs)
})

const addNewJob = getMiddleware({
    actionType: types.ADD_NEW_JOB,
    onRequest: apiRequestHandler(service.addNewJob)
})

const deleteJob = getMiddleware({
    actionType:types.DELETE_JOB,
    onRequest:apiRequestHandler(service.deleteJob)
})

const changeState = getMiddleware({
    actionType:types.CHANGE_STATE,
    onRequest:apiRequestHandler(service.changeState)
})

const editJob = getMiddleware({
    actionType:types.EDIT_JOB,
    onRequest:apiRequestHandler(service.editJob)
})

export default [
    getAllJobs,
    addNewJob,
    deleteJob,
    changeState,
    editJob,
];
  