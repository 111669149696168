import React from "react";
import { Provider } from "react-redux";
import PublicRoutes from "./router";
import { store, history } from "./redux/store.ts";
import "./styles/styles.less";

const DashApp = () => (
	<Provider store={store}>
		<PublicRoutes history={history} />
	</Provider>
);

export default DashApp;
