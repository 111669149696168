export const actionRequest = (action: string) => {
  return action + '_REQUEST';
};

export const actionSuccess = (action: string) => {
  return action + '_SUCCESS';
};

export const actionFailed = (action: string) => {
  return action + '_FAILED';
};

export const performOperation = async (
  getState: any,
  dispatch: any,
  promise: (data: { brandId?: string }) => Promise<any>,
  action: any,
) => {
  try {
    const state = await getState();
    const result = await promise({ brandId: state.auth.brandId });
    dispatch({
      type: actionSuccess(action),
      payload: result,
    });
  } catch (e) {
    dispatch({
      type: actionFailed(action),
      payload: 'Respository Error!',
    });
  }
};
  