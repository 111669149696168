import {
	configureStore,
	MiddlewareArray,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import reducers from "./reducers";
import middlewares from "./middlewares";
export const history = createBrowserHistory();

export const store = configureStore({
	reducer: reducers,
	middleware: new MiddlewareArray().concat(middlewares).concat(thunk),
	devTools: process.env.REACT_APP_ENV === "production" ? false : true,
});
export type RootState = ReturnType<typeof store.getState>;
