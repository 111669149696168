import React, { Component, Suspense } from "react";
import enUS from "antd/lib/locale-provider/en_US";
import { connect } from "react-redux";
import {
  Layout,
  ConfigProvider,
  notification,
  Skeleton,
} from "antd";
import { each, isEmpty } from "lodash";
import { openModal } from "../../redux/App/action.js";
import { logOutRequest } from "../../redux/Authentication/action";
import { fetchLoggedInAgentRequest as fetchProfile } from "../../redux/Authentication/action";
import AppRouter from "./AppRouter";
import ModalSelector from "components/uielements/modal/modalselector";
import { ThemeProvider } from "styled-components";
import theme from "../../theme/index.ts";
import Sidebar from "../Sidebar";
const { Content } = Layout;

export class App extends Component {
  state = {
    socketConn: false,
  };

  componentDidMount() {
    this.props.fetchProfile();
    window.addEventListener("hashchange", this.onHashChangeEvent, false);
  }

  onHashChangeEvent = (e) => {
    const { hashRouteDrawer } = this.props.App;
    if (!hashRouteDrawer) {
      this.props.toggleHashDrawer(true);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { UI, removeErrors } = this.props;
    const { errors } = UI;
    each(errors, (err, key) => {
      notification.error({
        message: "Error Occurred!",
        description:
          err && err.length > 0 ? err[0].description : "ERR_CODE: " + key,
      });
    });
    if (!isEmpty(errors)) removeErrors();
  }

  onCloseHashDrawer = () => {
    this.props.history.push(
      this.props.location.pathname + this.props.location.search
    );
    this.props.toggleHashDrawer(false);
  };

  render() {
    const { url } = this.props.match;
    const { App, Profile } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <ConfigProvider locale={enUS}>
          <Layout style={{ height: "100vh" }}>
            {App.modal ? (
              <ModalSelector {...App.modal} history={this.props.history} />
            ) : null}

            <Layout
              style={{
                flexDirection: "row",
                overflowX: "hidden",
              }}
            >
              <Sidebar url={url} />
              <Suspense fallback={ <Skeleton active />}>
                <Layout style={{ height: "100vh" }}>
                  <Content className="p-layout">
                    {!Profile && (
                      <div style={{ padding: 16 }}>
                        {" "}
                        <Skeleton active />
                      </div>
                    )}
                    {Profile && <AppRouter url={url} />}
                  </Content>
                </Layout>
              </Suspense>
            </Layout>
          </Layout>
        </ConfigProvider>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (state) => {
  const { UI, App, Auth } = state;
  return {
    UI: UI,
    App: App,
    Profile: Auth.loggedInUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name, data) => dispatch(openModal(name, data)),
    logOutRequest: () => dispatch(logOutRequest()),
    fetchProfile: () => dispatch(fetchProfile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
