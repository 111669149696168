export const checkForDoneAction = (data, actionType) =>
{
    return data[actionType];
};

export const sanitizedObject = (data) => {
    let result = data;
    if (Object.keys(result).length > 0) {
        Object.keys(result).map(key => {
            if(typeof result[key] === "string") {
                result[key] = result[key].trim();
            }
        })
	}
    return result;
}

export const getFileNameFromAttachment = (disposition) =>
{
    let filename = "";
    if (disposition && disposition.indexOf("attachment") !== -1)
    {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }
    if (disposition && disposition.indexOf("inline") !== -1)
    {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }
    return filename;
};