import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  Jobs
} from "../Pages/";
import { useSelector } from "react-redux";
import UnAuthorizedPage from "containers/Pages/unAuthorized";

function AppRouter(props) {
    const { url } = props;
    const { loggedInUser } = useSelector((store: MyTypes.Store) => store.Auth);

    const allRoutes = [
      {
        path: `${url}`,
        component: Jobs
      }
    ]

    return (
      <Switch>
        {allRoutes && allRoutes.map((route, id) =>
            {
              if (loggedInUser)
              {
                return (
                    <Route path={route.path} component={route.component} />
                )
              }
              else{
                return (
                    <Route path={route.path} component={UnAuthorizedPage} />
                )
              }
            }
          )
        }

      </Switch>
  );
}

export default AppRouter;
