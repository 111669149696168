import React from "react";
import { Modal, Button, Alert } from "antd";
import { isArray } from "lodash";
import { closeModal } from "../../../redux/App/action.js";
import { store } from "../../../redux/store";
import "./style.less";
type Props = {
	title: string,
	noHeader?: boolean,
	noFooter?: boolean,
	onSave?: Function,
	loading?: boolean,
	onSaveDisabled?: boolean,
	height?: string,
	errors?: Array | string | null,
	width?: number,
	cancelText?: string,
	className?: string,
	okText?: string,
	maskClosable?: boolean,
	closable?: boolean,
	fullscreen?: boolean,
	onSave?: Function,
	onCancel?: Function,
    children?: React.ReactElement
};
function CustomModal(props: Props) {
	const {
		fullscreen,
		errors,
		cancelText,
		okText,
		closable,
		noHeader,
		noFooter,
		className,
		maskClosable,
		onCancel,
		onSave,
		loading,
		onSaveDisabled
	} = props;
	let showError = null;
	if (isArray(errors)) {
		showError = errors.length > 0 ? errors.join(" | ") : null;
	}
	const title = (
		<div className="fl-modal-header">
			<div className="title">{props.title}</div>
			{closable ? (
				<div
					className="close-action"
					onClick={() => store.dispatch(closeModal())}
				>
					<i className="icon ion-ios-close" />
				</div>
			) : null}
		</div>
	);
	const footer = [
		<Button
			key="submit"
			type="primary"
			disabled={onSaveDisabled}
			loading={loading}
			onClick={onSave}
		>
			{okText}
		</Button>
	];
	if (cancelText) {
		footer.push(
			<Button
				key="cancel"
				disabled={onSaveDisabled}
				loading={loading}
				onClick={() => {
					if (onCancel) onCancel();
					store.dispatch(closeModal());
				}}
			>
				{cancelText}
			</Button>
		);
	}
	return (
		<Modal
			width={fullscreen ? "100%" : props.width}
			className={
				(className ? "fl-modal " + className : "fl-modal") +
				(fullscreen ? " fl-modal-fullscreen" : "")
			}
			wrapClassName="fl-modal-wrapper"
			title={noHeader ? null : title}
			maskClosable={maskClosable}
			onCancel={() => store.dispatch(closeModal())}
			closable={false}
			visible
			bodyStyle={{ height: fullscreen ? "100%" : props.height }}
			maskStyle={{ background: "#202b5782" }}
			footer={noFooter ? null : footer}
		>
			<React.Fragment>
				{showError && (
					<Alert
						type="error"
						message="Error"
						description={showError}
						banner
					/>
				)}
				{showError && <br />}
				{props.children}
			</React.Fragment>
		</Modal>
	);
}

CustomModal.defaultProps = {
	fullscreen: false,
	errors: null,
	loading: false,
	noHeader: false,
	onSaveDisabled: false,
	height: "420px",
	width: 640,
	okText: "Save",
	cancelText: "",
	closable: true,
	maskClosable: false
};

export default CustomModal;
