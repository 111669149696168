// @flow
import React from "react";
import { Form, Select } from "antd";

const Option = Select.Option;
const FormItem = Form.Item;

function CustomInput(props) {
	const {
		required,
		showSearch,
		getFieldDecorator,
		label,
		name,
		disabled,
		className,
		validatorMessage,
		values,
		placeholder,
		defaultValue,
		onSelect,
		filterOption,
		optionLabelProp,
		onChange,
		horizontalLayout,
		labelCol,
		wrapperCol,
		style,
		mode,
		clear
	} = props;
	const crules = [{ required, message: validatorMessage }];
	let layout = horizontalLayout
		? {
				labelCol: labelCol || {
					span: 10,
					style: { textAlign: "left" },
				},
				wrapperCol: wrapperCol || { span: 14 },
		  }
		: {};
	const _onSelect = onSelect
		? (val, e) => onSelect(val, e.props.valObj, e)
		: null;
	const defaultFilterOptions =
		showSearch && !filterOption
			? (input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
			: null;
	if (!getFieldDecorator) {
		return (
			<Select
				mode={mode}
				allowClear={clear}
				showSearch={showSearch}
				className={className}
				defaultValue={defaultValue}
				disabled={disabled}
				onSelect={_onSelect}
				onChange={onChange}
				optionLabelProp={optionLabelProp}
				placeholder={placeholder}
				filterOption={filterOption || defaultFilterOptions}
				style={style ? style : { width: "100%", maxWidth: 250 }}
			>
				{values.map((item, index) => (
					<Option
						key={index}
						label={item.label}
						value={item.value}
						valObj={item}
					>
						{item.label}
					</Option>
				))}
			</Select>
		);
	}
	return (
		<FormItem label={label} {...layout}>
			{!getFieldDecorator ||
				getFieldDecorator(name, {
					rules: crules,
					initialValue: defaultValue,
				})(
					<Select
						mode={mode}
						showSearch={showSearch}
						className={className}
						allowClear={clear}
						disabled={disabled}
						onSelect={onSelect}
						optionLabelProp={optionLabelProp}
						placeholder={placeholder}
						filterOption={filterOption || defaultFilterOptions}
						style={style ? style : { width: "100%" }}
					>
						{values && values.map((item, index) => (
							<Option
								key={index}
								label={item.label}
								value={item.value}
								valObj={item}
							>
								{item.label}
							</Option>
						))}
					</Select>
				)}
		</FormItem>
	);
}
CustomInput.defaultProps = {
	mode: "default",
	size: "default",
	showSearch: false,
	required: false,
	getFieldDecorator: null,
	disabled: false,
	optionLabelProp: "label",
	validatorMessage: "This field is required",
};
export default CustomInput;
