import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../../image/rob.png';

const UnAuthorizedPage = () => {

    return (
        <div className="iso404Page">
            <div className="iso404Content">
                <h1>
                    Access Denied!!
                </h1>
                <h3>
                    Looks like you don't have access to this page
                </h3>
            </div>

            <div className="iso404Artwork">
                <img alt="#" src={Image} />
            </div>
        </div>
    )
}

export default UnAuthorizedPage;