if (process.env.REACT_APP_ENV === "production") 
{
  module.exports = require("./service.prod");
} 
else if (process.env.REACT_APP_ENV === "staging") 
{
  module.exports = require("./service.staging");
} 
else if (process.env.REACT_APP_ENV === "karma") 
{
  module.exports = require("./service.karma");
} 
else 
{
  module.exports = require("./service.dev");
}