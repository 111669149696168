import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { openModal } from "../../../../redux/App/action.js";
import cronstrue from "cronstrue";
import {
  Table,
  Button,
  Popconfirm,
  Drawer,
  Tabs,
  message,
  Form,
  Row,
  Col,
  Checkbox,
  Cascader,
  InputNumber,
  TimePicker,
} from "antd";
import CustomSelect from "../../../../components/uielements/CustomSelect";
import Input from "../../../../components/uielements/input";
import { StatusBadge } from "../../../../components/uielements/StatusBadge";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import JobsHeader from "./JobsHeader";
import { MyTypes } from "../../../../types/MyTypes";
import {
  fetchAllJobsRequest,
  deletejobRequest,
  actions,
  changeState,
  editJob,
} from "../../../../redux/JobScheduler/action";
import { checkForDoneAction, sanitizedObject } from "../../../../utils/helpers";
import { removeDoneActions } from "../../../../redux/_UI/action";
import { options, checkoptions } from "./JobsUtils";
import "./style.less";

const mapStateToProps = (state: MyTypes.Store) => {
  const { JobsScheduler, UI } = state;

  return {
    jobs: JobsScheduler.jobs,
    loading: UI.isLoading,
    ui: UI,
    pagination: JobsScheduler.pagination,
  };
};

const mapDispatchToProps = {
  openModal,
  removeDoneActions,
  changeState,
  fetchAllJobsRequest,
  editJob,
  deletejobRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends MyTypes.RouterWithForm, ReduxProps {}
const formatForTimePicker = "HH:mm";

const Jobs = (props: Props) => {
  const {
    openModal,
    editJob,
    fetchAllJobsRequest,
    jobs,
    loading,
    changeState,
    deletejobRequest,
    ui,
    removeDoneActions,
    form,
  } = props;

  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    fetchAllJobsRequest();
  }, []);

  useEffect(() => {
    if (checkForDoneAction(ui.doneActions, actions.DELETE_JOB)) {
      message.success("Job deleted successfully!");
      removeDoneActions([actions.DELETE_JOB]);
      fetchAllJobsRequest();
    }

    if (checkForDoneAction(ui.doneActions, actions.CHANGE_STATE)) {
      message.success("Job State Changed successfully!");
      removeDoneActions([actions.CHANGE_STATE]);
      fetchAllJobsRequest();
    }
    if (checkForDoneAction(ui.doneActions, actions.EDIT_JOB)) {
      message.success("Job Edited  successfully!");
      removeDoneActions([actions.EDIT_JOB]);

      setFormControlObject((prev) => {
        return { ...prev, drawer: false };
      });
      setDates([]);
      fetchAllJobsRequest();
    }
  }, [
    checkForDoneAction(ui.doneActions, actions.DELETE_JOB),
    checkForDoneAction(ui.doneActions, actions.CHANGE_STATE),
    checkForDoneAction(ui.doneActions, actions.EDIT_JOB),
  ]);

  const format = "YYYY/MM/DD";
  const [formControlObject, setFormControlObject] = useState({
    drawer: false,
    componentDisabled: true,
    cronInterval: "Cron",
    custom: false,
    editState: "Select",
    data: {
      id: "",
      name: "",
      cron: "",
      timeout: ""
    },
    time: "",
    range: 7,
    repeat: 1,
    startDate: [],
    end: false,
    endDate: "",
    customObject: {
      repeatEvery: 0,
      Minute: undefined,
      Hour: undefined,
      Day: [12],
      Month: undefined,
      Year: undefined,
    },
  });

  const [dates, setDates] = useState<any[]>([]);

  const columns = [
    {
      title: "Job Name",
      dataIndex: "name",
      key: "Jobname",
    },
    {
      title: "Cron Time",
      dataIndex: "cron",
      key: "Crontime",
      render: (data: any) => {
        let result = cronstrue.toString(data);
        return <span>{result}</span>;
      },
    },

    {
      title: "Last Run Status",
      dataIndex: "status",
      key: "Status",
      render: (key: any) => {
        const isSuccessful = ["SUCCESS", "ACTIVE", "STARTED"].includes(key);
        return (
          <StatusBadge type={isSuccessful ? "success" : "error"}>
            {key.toUpperCase()}
          </StatusBadge>
        );
      },
    },
    {
      title: "Current State",
      dataIndex: "state",
      key: "State",
      render: (key: any) =>
        !key ? (
          <StatusBadge type="info">PAUSED</StatusBadge>
        ) : (
          <StatusBadge type="processing">RUNNING</StatusBadge>
        ),
    },
    {
      title: "Last Run",
      dataIndex: "last_run",
      key: "LastRun",
      render: (name: any) => {
        let event;
        if (name) {
          event = new Date(name);
        }
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return name ? (
          <>
            <span>{event.toLocaleDateString(undefined, options)}</span>
            <br></br>
            <span>at {event.toLocaleTimeString([], { hour12: false })}</span>
          </>
        ) : (
          <span>No Data Available</span>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text: any, record: any) => {
        return (
          <>
            <Button
              className="btn btn--link"
              onClick={() =>
                changeState({ jobID: record.id, state: !record.state })
              }
            >
              {!record.state ? "Play" : "Pause"}
            </Button>
            <Button
              className="btn btn--link"
              onClick={() => {
                setFormControlObject((prev) => {
                  return { ...prev, drawer: true, data: record };
                });
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deletejobRequest({ jobID: record.id })}
            >
              <Button className="btn btn--link">Delete</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let data: Date[] = [];
    for (let i = 0; i < dates.length; i++) {
      data.push(new Date(dates[i].format()));
    }
    setFormControlObject((prev) => {
      return { ...prev, startDate: data } as typeof prev;
    });
  }, [dates]);

  function handleRange(e: any) {
    let value = e[0];
    switch (value) {
      case "Day":
        setFormControlObject((prev) => ({ ...prev, range: 30 }));
        break;
      case "Month":
        setFormControlObject((prev) => ({ ...prev, range: 12 }));
        break;
      case "Hour":
        setFormControlObject((prev) => ({ ...prev, range: 24 }));
        break;
      default:
        setFormControlObject((prev) => ({ ...prev, range: 60 }));
        break;
    }
    setFormControlObject((prev) => {
      return {
        ...prev,
        customObject: { ...prev.customObject, repeatEvery: 1 },
      };
    });
  }

  const onSave = () => {
    validateFields((errs, vals) => {
      if (!errs) {
        const retrieved = sanitizedObject(vals);
        if(formControlObject.time===""){
          message.error("Please select time")
          return
        }
        if(formControlObject.startDate.length==0 && retrieved.repeat!=='Daily'){
          message.error("Please select start date")
          return 
        }
        const postDate = {
          id: formControlObject.data.id,
          cron: retrieved.cron,
          name: retrieved.name,
          time: formControlObject.time,
          endDate: formControlObject.endDate,
          timeout: retrieved.timeout,
          repeat: retrieved.repeat,
          type: retrieved.ScheduleType,
          custom: { ...formControlObject.customObject },
          startDate: formControlObject.startDate,
        };
        editJob(postDate);
      }
    });
  };

  return (
    <React.Fragment>
      <JobsHeader name={"Manage Schedule Jobs"} />

      <div className="p-layout-content dim">
        <Tabs
          style={{ background: "white", padding: 16, alignItems: "center" }}
          activeKey="ScheduledJobs"
        >
          <Tabs.TabPane tab="Scheduled Jobs" key="ScheduledJobs">
            <Table
              className="p-table"
              rowKey="Id"
              dataSource={jobs}
              pagination={{
                pageSizeOptions: ["10", "20", "30"],
                showSizeChanger: true,
                defaultCurrent: 1,
              }}
              columns={columns}
              loading={loading}
            />

            <Drawer
              destroyOnClose
              onClose={() => {
                setFormControlObject((prev) => {
                  return { ...prev, drawer: false };
                });
              }}
              visible={formControlObject.drawer}
              placement="right"
              width={700}
            >
              <JobsHeader name={"Edit Job"} />
              <br></br>

              <div style={{ marginTop: "5vh", marginLeft: "1vw" }}>
                <Form
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 18 }}
                  layout="horizontal"
                  style={{ maxWidth: 750 }}
                >
                  <Row>
                    <Col span={24}>
                      <Input
                        label="Job Name"
                        disabled
                        name="name"
                        required
                        getFieldDecorator={getFieldDecorator}
                        defaultValue={
                          formControlObject.data
                            ? formControlObject.data.name
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label="Cron Time"
                        name="cron"
                        required
                        disabled
                        defaultValue={
                          formControlObject.data
                            ? formControlObject.data.cron
                            : ""
                        }
                        getFieldDecorator={getFieldDecorator}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomSelect
                        name="ScheduleType"
                        label="Schedule Type"
                        getFieldDecorator={getFieldDecorator}
                        values={[{ value: 1, label: "Cron" }]}
                        defaultValue="Cron"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={3} style={{ marginLeft: "3.2vw" }}>
                      <label>Edit Cron :</label>
                    </Col>
                    <Col span={8}>
                      <DatePicker
                        value={dates}
                        onChange={setDates}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        placeholder="Start Date"
                        style={{
                          padding: "1.5vh",
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        onChange={(e) => {
                          if (formControlObject.end) {
                            setFormControlObject((prev) => {
                              return { ...prev, endDate: "" };
                            });
                          }
                          setFormControlObject((prev) => {
                            return { ...prev, end: !formControlObject.end };
                          });
                        }}
                      >
                        Do You need End Date
                      </Checkbox>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} style={{ marginTop: "1.5vh" }}></Col>
                    {formControlObject.end ? (
                      <Col md={6}>
                        <DatePicker
                          onChange={(e: any) =>
                            setFormControlObject((prev) => {
                              return {
                                ...prev,
                                endDate: (new Date(e.format())).toISOString(),
                              };
                            })
                          }
                          placeholder="End Date"
                          style={{
                            width: "8vw",
                            padding: "1.7vh",
                            marginTop: "1.5vh",
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col span={6}>
                      <TimePicker
                        required="true"
                        onChange={(e) => {
                          let time = e._d.toLocaleTimeString("en-US", {
                            hour12: false,
                          });

                          setFormControlObject((prev) => {
                            return { ...prev, time: time };
                          });
                        }}
                        format={formatForTimePicker}
                        style={{
                          width: 150,
                          marginTop: "1.5vh",
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <CustomSelect
                        name="repeat"
                        onSelect={(e) => {
                          if (e == "Custom") {
                            setFormControlObject((prev) => {
                              return { ...prev, custom: true };
                            });
                            setFormControlObject((prev) => {
                              return {
                                ...prev,
                                customObject: {
                                  ...prev.customObject,
                                  repeatEvery: 1,
                                },
                              };
                            });

                            setFormControlObject((prev) => {
                              return { ...prev, repeat: 1 };
                            });
                          } else {
                            setFormControlObject((prev) => {
                              return { ...prev, custom: false };
                            });
                          }
                        }}
                        getFieldDecorator={getFieldDecorator}
                        values={[
                          { value: "Daily", label: "Daily" },
                          { value: "false", label: "Doesn't Repeat" },
                          { value: "Custom", label: "Custom" },
                        ]}
                        defaultValue="false"
                        required
                        style={{ width: 120, marginTop: "1.5vh" }}
                      />
                    </Col>
                  </Row>
                  {formControlObject.custom ? (
                    <>
                      <Col md={24}>
                        <label style={{ marginLeft: "1.3vw" }}>
                          Repeat Every :
                        </label>

                        <InputNumber
                          onChange={(e) => {
                            setFormControlObject((prev) => {
                              return { ...prev, repeat: e } as typeof prev;
                            });
                            setFormControlObject((prev) => {
                              return {
                                ...prev,
                                customObject: {
                                  ...prev.customObject,
                                  repeatEvery: e,
                                },
                              } as typeof prev;
                            });
                          }}
                          style={{
                            width: 100,
                            marginRight: "1vw",
                            marginLeft: "1vw",
                          }}
                          min={1}
                          max={formControlObject.range}
                          value={formControlObject.repeat}
                        />
                        <Cascader
                          style={{ width: 150 }}
                          options={options}
                          placeholder="Please select"
                          onChange={(e) => {
                            setFormControlObject((prev) => {
                              return {
                                ...prev,
                                customObject: {
                                  ...prev.customObject,
                                  Day: undefined,
                                  Month: undefined,
                                  Hour: undefined,
                                  Minute: undefined,
                                  [e[0]]: true,
                                },
                              };
                            });
                            handleRange(e);
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {formControlObject.customObject.Day ? (
                    <Row>
                      <Col span={5}></Col>
                      <Col span={19}>
                        <Checkbox.Group
                          options={checkoptions}
                          style={{
                            marginTop: "2.5vh",
                            marginBottom: "2vh",
                          }}
                          onChange={(e) =>
                            setFormControlObject((prev) => {
                              return {
                                ...prev,
                                customObject: {
                                  ...prev.customObject,
                                  Day: e,
                                },
                              } as typeof prev;
                            })
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <Col md={24} style={{ marginTop: "1vh" }}>
                    <CustomSelect
                      name="timeout"
                      label="Timeout"
                      getFieldDecorator={getFieldDecorator}
                      values={[
                        { value: "True", label: "True" },
                        { value: "False", label: "False" },
                      ]}
                      defaultValue={
                        formControlObject.data
                          ? formControlObject.data.timeout
                          : ""
                      }
                      required
                    />
                  </Col>
                  <Col md={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "5%",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ marginRight: "2vw" }}
                        onClick={onSave}
                      >
                        Save
                      </Button>
                      <Button
                        type="danger"
                        onClick={() => {
                          setFormControlObject((prev) => {
                            return { ...prev, drawer: false };
                          });

                          setFormControlObject((prev) => {
                            return { ...prev, editState: "Select" };
                          });

                          setFormControlObject((prev) => {
                            return { ...prev, data: {} } as typeof prev;
                          });
                          setFormControlObject({
                            drawer: false,
                            componentDisabled: true,
                            cronInterval: "Cron",
                            custom: false,
                            editState: "Select",
                            data: null,
                            time: "",
                            range: 7,
                            repeat: 1,
                            startDate: [],
                            end: false,
                            endDate: "",
                            customObject: {
                              repeatEvery: 0,
                              Minute: undefined,
                              Hour: undefined,
                              Day: undefined,
                              Month: undefined,
                              Year: undefined,
                            },
                          });
                          setDates([]);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Form>
              </div>
            </Drawer>
          </Tabs.TabPane>
          <div className="tab-toolbar pull-right">
            <Button
              size="large"
              className="btn btn--primary btn--round "
              onClick={() => {
                openModal("JobAddScheduler");
              }}
            >
              + Add Job
            </Button>
          </div>
        </Tabs>
      </div>
      
    </React.Fragment>
  );
};
const Wrapped = Form.create()(Jobs);
export default connector(Wrapped);
