import { apiGateway } from "../config/service";
import { postRequest, getRequest } from "./_helper";

class JobService
{
    static getAllJobs = () => {
        return getRequest(apiGateway.baseUrl, 'api-gateway/console/jobs/all', true);
    }

    static addNewJob = (data: any) => {      
        return postRequest(apiGateway.baseUrl, 'api-gateway/console/job/add', data);
    }

    static deleteJob = (data: any) => {     
        return postRequest(apiGateway.baseUrl, 'api-gateway/console/job/delete', data);
    }

    static changeState = (data: any) => {
        return postRequest(apiGateway.baseUrl, 'api-gateway/console/job/changestate', data);
    }

    static editJob = (data: any) => {
        return postRequest(apiGateway.baseUrl, 'api-gateway/console/job/edit', data);
    }
}

export default JobService;