import React from "react";
import "./style.less";

type Props = {
	style?: React.CSSProperties;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	type:
		| "success"
		| "warning"
		| "error"
		| "ghost"
		| "info"
		| "default"
		| "processing";
	shape?: "rectangle" | "pill" | "default";
	background?: "default" | "none";
	children?: React.ReactNode;
};

export const StatusBadge: React.FC<Props> = props => {
	const { type, shape, style, onClick, background } = props;
	return (
		<div
			onClick={onClick}
			style={style}
			className={`status-badge status-badge--${type} badge-shape--${shape} badge-shape--${background}`}
		>
			{props.children}
		</div>
	);
};

StatusBadge.defaultProps = {
	type: "default",
	shape: "default",
	background: "default"
};
