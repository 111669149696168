let mobile = {
    pattern: /^[2-9]{1}[0-9]{9}$/,
    message: "Phone Number is Incorrect"
  };
  let email = {
    type: "email"
  };
  let pincode = {
    pattern: /^[1-9]?[0-9]{5}$/,
    len: 6,
    message: "Pincode must be of 6 digits"
  };
  
  let integer = {
    pattern: /^[-+]?[0-9]+$/,
    message: "Only Integers are allowed"
  };
  
  let float = {
    message: "Only Float are allowed",
    pattern: /^[-+]?[0-9]+\.[0-9]+$/
  };
  
  let number = {
    message: "Only Numbers are allowed",
    pattern: /^[+]?[0-9]+(\.[0-9]+)?$/
  };
  
  let amount = {
    message: "Amount is Incorrect",
    pattern: /^[+]?[0-9]+(\.[0-9][0-9])?$/
  };
  
  // ^\d+(\.\d{1,2})?$
  
  let positiveNumber = {
    pattern: /^[+]?[0-9]+$/,
    message: "Only Number are allowed"
  };
  let positiveNegativeNumbers = {
    pattern: /^\-?[0-9]\d{0,9}$/,
    message: "Only Number are allowed"
  };
  let positiveFloat = {
    pattern: /^(?:[1-9][0-9]*|0)?(?:\.[0-9]+)?$/,
    message: "Incorrect Format. Example 10.00"
  };
  let pannumber = {
    message: "Pan Number must be exactly 10 characters",
    len: 10
  };
  let aadhar = {
    message: "Aadhar Number must be exactly 12 characters",
    len: 12
  };
  
  let ifsc = {
    pattern: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
    message: "IFSC Code is Invalid."
  };
  
  let accountNumber = {
      pattern: /^\d{2,50}$/,
      message: "Account Number should contain only digits and must be less than 50 digits"
  };
  
  let accountHolderName = {
      pattern:/^[A-Za-z0-9\s]{1,50}$/,
      message: "Name should contain only characters and must be less than 50 characters",
  };
  
  let benificiaryName = {
      pattern:/^[A-Za-z0-9\s]{1,200}$/,
      message: "Name should contain only characters and must be less than 200 characters",
  };
  
  let benificiaryNickName = {
      pattern:/^[A-Za-z0-9]{1,200}$/,
      message: "Name should contain only alpha numberic characters(No spaces allowed) and must be less than 200 characters.",
  };
  
  let strongPassword = {
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      message: "Password must be a minimum of 8 characters including Number, Upper, Lower And one Special Character"
  }
  
  let rules = {};
  rules.mobile = mobile;
  rules.email = email;
  rules.number = number;
  rules.amount = amount;
  rules.pannumber = pannumber;
  rules.positiveNumber = positiveNumber;
  rules.pincode = pincode;
  rules.positiveFloat = positiveFloat;
  rules.aadhar = aadhar;
  rules.ifsc = ifsc;
  rules.positiveNegativeNumbers = positiveNegativeNumbers;
  rules.accountNumber = accountNumber;
  rules.accountHolderName = accountHolderName;
  rules.strongPassword = strongPassword;
  rules.benificiaryName = benificiaryName;
  rules.benificiaryNickName = benificiaryNickName;
  export default rules;
  