import { actions as types } from "./action";
import
{
  checkStatus,
  checkResponseCode,
  statusMessages,
  fileDownloadHelper
} from "../../utils/ApiUtils";
import { message } from "antd";

// this middleware care only for API calls
export const api = ({ dispatch }) => (next) => (action) =>
{
  if (action.type === types.API_REQUEST)
  {
    const { request, onSuccess, onError, context, options = {} } = action.meta;
    if (options.downloadable)
    {
      fetch(request)
        .then(fileDownloadHelper)
        .then(data => dispatch({ type: onSuccess, payload: data, context }))
        .catch(error =>
        {
          dispatch({ type: onError, payload: error, context });
        });
    }
    else
    { 
      fetch(request)
        .then(checkStatus)
        .then(checkResponseCode)
        .then((response) => response.PayLoad)
        .then((data) =>
        {
          dispatch({ type: onSuccess, payload: data, context: context });
        })
        .catch((error) =>
        {
          if (error.status)
          {
            if ([401, 403, 440].indexOf(error.status) >= 0)
            {
              dispatch({ type: statusMessages[error.status].type });
              message.error(statusMessages[error.status].message);
              dispatch({
                type: onError,
                payload: error,
                context: context,
              });
            } else
            {
              dispatch({
                type: onError,
                payload: error.payload,
                context: context,
              });
            }
          } else
          {
            dispatch({
              type: onError,
              context: context,
              payload: {
                message: "Network Error!",
                payload: error,
              },
            });
          }
        });
    }
  }
  return next(action);
};

export default [api];
