import React from "react";
import * as Modals from "../../../modals";

type Props = {
  modalName: string,
  modalProps: Object
};

function ModalSelector(props: Props) {
  if (!props.modalName) return null;
  const SpecificModal = Modals[props.modalName];
  return <SpecificModal {...props} />;
}

export default ModalSelector;
