// this theme follows the System UI Theme Specification
// Ref: https://system-ui.com/theme

const colors = {
    white: "#fff",
    primary: {
      100: "#E0E3FD",
      200: "#ecf0ff",
      300: "#a9beff",
      400: "#2333EE",
      500: "#101FCE",
      600: "#0E1CB7",
      700: "#010C87",
      800: "#081170",
      900: "#070D58",
      1000: "#050A40"
    },
    grays: {
      100: "#F9F9FA",
      200: "#f1f1f1",
      300: "#DBDDE0",
      400: "#CACCCF",
      500: "#A0A4A8",
      600: "#52575C",
      700: "#25282B",
      800: "#1E2123",
      900: "#1A1C1E",
      1000: "#0E0F10"
    },
    blues: {
      100: "#E5F2FF",
      200: "#E5F2FF",
      300: "#CCE5FF",
      400: "#99CBFF",
      500: "#66B2FF",
      600: "#3398FF",
      700: "#007EFF",
      800: "#0078F2",
      900: "#004C99",
      1000: "#003266"
    },
    reds: {
      100: "#FFF3F3",
      200: "#FEE4E4",
      300: "#FDB2B2",
      400: "#FD9999",
      500: "#FC8080",
      600: "#FC6767",
      700: "#FB4E4E",
      800: "#E93C3C",
      900: "#D91919",
      1000: "#AC1313"
    },
    green: {
      100: "#EDFCF3",
      200: "#D8F8E5",
      300: "#C2F4D6",
      400: "#ACF0C8",
      500: "#81E9AB",
      600: "#56E18E",
      700: "#40DD7F",
      800: "#2AC769",
      900: "#1AB759",
      1000: "#129446"
    },
    yellow: {
      100: "#FFF1D1",
      200: "#FFE29E",
      300: "#FFDA85",
      400: "#FFD36B",
      500: "#FFCB52",
      600: "#FFC438",
      700: "#FFBC1F",
      800: "#F6A609",
      900: "#E89806",
      1000: "#D28800"
    }
  };
  
  const theme = {
    colors,
    space: [0, 4, 8, 12, 16, 20, 24, 28, 32],
    fontSizes: [
      "0px",
      "9px",
      "11px",
      "13px",
      "16px",
      "18px",
      "22px",
      "24px",
      "26px",
      "32px",
      "40px",
      "54px"
    ],
    fontWeights: {
      // matches Inter weights
      thin: 100,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900
    },
  
    // transition speeds in ms
    speeds: [0, "75ms", "100ms", "150ms", "200ms", "300ms", "500ms"],
  
    shadows: {
      // this part is ugly, this can be improved.
      // bonus: these are terrible names
      active: `inset 0px -2px 0px ${colors.blues[300]}`,
      underline: `inset 0px -2px 0px ${colors.grays[100] + "1a"}`,
      fadeunder: `inset 0px 8px 8px 0px ${colors.grays[700] + "cc"}`,
      card: `0 0 60px 0 #e4e7eb`,
      shadow1: `0 1px 5px rgba(0, 0, 0, 0.10), 0 1px 1px rgba(0, 0, 0, 0.10)`,
      mdShadows: {
        1: "0 1px 5px rgba(0, 0, 0, 0.10), 0 1px 1px rgba(0, 0, 0, 0.10)",
        2: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
        3: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
        4: "0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.16)"
      },
    },
  
    radii: {
      small: 2,
      medium: 4,
      large: 16,
      round: "50%"
    }
  };
  
  export default theme;
  