import types from "./action";
import { each } from "lodash";

export type State = {
  readonly isLoading: boolean;
  readonly loaderMeta: any;
  readonly errors: any;
  readonly spinners: any;
  readonly doneActions: any;
};

const initUi: State = {
  isLoading: false,
  loaderMeta: null,
  errors: {},
  spinners: {},
  doneActions: {}
};

export default function reducer(state = initUi, action: any) {
  switch (action.type) {
    case types.SHOW_SPINNER: {
      let spinners = { ...state.spinners };
      let context = action.payload.context;
      let isLoading = state.isLoading;
      if (!context) {
        spinners.global = true;
        isLoading = true;
      } else {
        spinners[context] = true;
      }
      return { ...state, spinners: spinners, isLoading: isLoading };
    }

    case types.HIDE_SPINNER: {
      let spinners = { ...state.spinners };
      let isLoading = state.isLoading;
      if (!action.payload) {
        // spinners = {};
        isLoading = false;
        spinners.global = false;
      }
      if (action.payload) {
        if (typeof action.payload === "string") {
          spinners[action.payload] = false;
        }
        if (action.payload instanceof Array) {
          each(action.payload, (key: any) => {
            spinners[key] = false;
          });
        }
      }
      return { ...state, spinners: spinners, isLoading };
    }

    case types.SHOW_ERROR: {
      let errors = { ...state.errors };
      let context = action.payload.context;
      if (!errors[context]) {
        errors[context] = [action.payload];
      } else {
        errors[context] = [...errors[context], action.payload];
      }
      return { ...state, errors: errors };
    }

    case types.REMOVE_ERRORS: {
      let errors = state.errors;
      if (!action.payload) {
        errors = {};
      }
      if (action.payload && action.payload instanceof Array) {
        each(action.payload, (key: any) => {
          delete errors[key];
        });
      }
      return { ...state, errors: errors };
    }

    case types.ADD_DONE_ACTIONS: {
      let doneActions = { ...state.doneActions };
      let context = action.payload.action;
      doneActions[context] = action.payload.payload || true;
      return { ...state, doneActions: doneActions };
    }

    case types.REMOVE_DONE_ACTIONS: {
      let doneActions = { ...state.doneActions };
      if (!action.payload) {
        doneActions = {};
      }
      if (action.payload && action.payload instanceof Array) {
        each(action.payload, (key: any) => {
          doneActions[key] = false;
        });
      }
      return { ...state, doneActions: doneActions };
    }
    
    default:
      return state;
  }
}
