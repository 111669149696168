import React from "react";

const JobsHeader = (props:any) => {
  return (
    <div className="p-layout-content-wrapper">
      <div className="p-layout-content__header">
        <div className="content__header-title">{props.name}</div>
        <div className="content__header-actions"></div>
      </div>
    </div>
  );
};

export default JobsHeader;
