import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Form } from 'antd';
import {
  message,
  Checkbox,
  Cascader,
  InputNumber,
  TimePicker,
  Col,
  Row,
} from "antd";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

// import type {} from "antd";
import CustomSelect from "../../components/uielements/CustomSelect";
import Input from "../../components/uielements/input";
import Modal from "../../components/uielements/modal";
import { closeModal, openModal } from "../../redux/App/action.js";
import { MyTypes } from "types/MyTypes";
import { checkForDoneAction, sanitizedObject } from "utils/helpers";
import {
  fetchAllJobsRequest,
  actions,
  addNewJob,
} from "../../redux/JobScheduler/action";
import {
  options,
  checkoptions,
} from "../../containers/Pages/protected/JobScheduler/JobsUtils";
import { removeDoneActions } from "../../redux/_UI/action";

const mapStateToProps = (state: MyTypes.Store) => {
  const { JobsScheduler, UI } = state;

  return {
    jobs: JobsScheduler.jobs,
    loading: UI.isLoading,
    ui: UI,
  };
};

const mapDispatchToProps = {
  openModal,
  closeModal,
  removeDoneActions,
  fetchAllJobsRequest,
  addNewJob,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends MyTypes.RouterWithForm, ReduxProps {}

const formatForTimePicker = "HH:mm";

const JobsAddModal = (props: Props) => {
  const {
    fetchAllJobsRequest,
    ui,
    closeModal,
    addNewJob,
    removeDoneActions,
    form,
  } = props;
  const { getFieldDecorator, validateFields } = form;

  const [formControlObject, setFormControlObject] = useState({
    name: "",
    componentDisabled: true,
    cronInterval: "Cron",
    custom: false,
    editState: "Select",
    data: null,
    time: "",
    range: 7,
    repeat: 1,
    startDate: [],
    end: false,
    endDate: "",
    customObject: {
      repeatEvery: 0,
      Minute: undefined,
      Hour: undefined,
      Day: undefined,
      Month: undefined,
      Year: undefined,
    },
  });

  const format = "YYYY/MM/DD";

  const [dates, setDates] = useState([]);

  function handlSave() {
    validateFields((errs, vals) => {
      if (!errs) {
        const retrieved = sanitizedObject(vals);
        if(formControlObject.time===""){
          message.error("Please select time")
          return
        }
        if(formControlObject.startDate.length==0 && retrieved.repeat!=='Daily'){
          message.error("Please select start date")
          return 
        }
        const postDate = {
          id: "",
          cron: "",
          name: retrieved.name,
          time: formControlObject.time,
          endDate: formControlObject.endDate,
          timeout: retrieved.timeout,
          repeat: retrieved.repeat,
          type: retrieved.ScheduleType,
          custom: { ...formControlObject.customObject },
          startDate: formControlObject.startDate,
        };

        addNewJob(postDate);
      }
    });
  }

  useEffect(() => {
    if (checkForDoneAction(ui.doneActions, actions.ADD_NEW_JOB)) {
      message.success("Jobs added successfully!");
      closeModal();
      removeDoneActions([actions.ADD_NEW_JOB]);
      fetchAllJobsRequest();
    }
  }, [checkForDoneAction(ui.doneActions, actions.ADD_NEW_JOB)]);

  function handleRange(e: any) {
    let value = e[0];
    if (value == "Day") {
      setFormControlObject((prev) => {
        return { ...prev, range: 30 };
      });
    } else if (value == "Month") {
      setFormControlObject((prev) => {
        return { ...prev, range: 12 };
      });
    } else if (value == "Hour") {
      setFormControlObject((prev) => {
        return { ...prev, range: 24 };
      });
    } else {
      setFormControlObject((prev) => {
        return { ...prev, range: 60 };
      });
    }
    setFormControlObject((prev) => {
      return { ...prev, custom: { ...prev.custom, repeatEvery: 1 } };
    });
  }

  useEffect(() => {
    let data: any[] = [];
    for (let i = 0; i < dates.length; i++) {
      data.push(new Date(dates[i].format()));
    }
    setFormControlObject((prev) => {
      return { ...prev, startDate: data };
    });
  }, [dates]);

  return (
    <React.Fragment>
      <Modal
        title="Add New Job"
        height="450px"
        okText="Add Job"
        cancelText="Cancel"
        width={700}
        closable={true}
        onSave={handlSave}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          style={{ maxWidth: 750 }}
        >
          <Row>
            <Col span={24}>
              <Input
                label="Job Name"
                onChange={(e) => {
                  let res = e.target.value.replaceAll(" ", "");
                  e.target.value = res;
                }}
                name="name"
                required
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomSelect
                name="ScheduleType"
                label="Schedule Type"
                getFieldDecorator={getFieldDecorator}
                values={[{ value: 1, label: "Cron" }]}
                defaultValue="Cron"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ marginLeft: "3.2vw" }}>
              <label>Edit Cron :</label>
            </Col>
            <Col span={8}>
              <DatePicker
                value={dates}
                onChange={setDates}
                multiple
                sort
                format={format}
                calendarPosition="bottom-center"
                plugins={[<DatePanel />]}
                placeholder="Start Date"
                style={{
                  padding: "1.5vh",
                }}
              />
            </Col>
            <Col span={8}>
              <Checkbox
                onChange={(e) => {
                  if (formControlObject.end) {
                    setFormControlObject((prev) => {
                      return { ...prev, endDate: undefined };
                    });
                  }
                  setFormControlObject((prev) => {
                    return { ...prev, end: !formControlObject.end };
                  });
                }}
              >
                Do You need End Date
              </Checkbox>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ marginTop: "1.5vh" }}></Col>
            {formControlObject.end ? (
              <Col md={6}>
                <DatePicker
                  onChange={(e) =>
                    setFormControlObject((prev) => {
                      return {
                        ...prev,
                        endDate: new Date(e.format()),
                      };
                    })
                  }
                  placeholder="End Date"
                  style={{
                    width: "8vw",
                    padding: "1.7vh",
                    marginTop: "1.5vh",
                  }}
                />
              </Col>
            ) : (
              ""
            )}
            <Col span={6}>
              <TimePicker
                onChange={(e) => {
                  let time = e._d.toLocaleTimeString("en-US", {
                    hour12: false,
                  });

                  setFormControlObject((prev) => {
                    return { ...prev, time: time };
                  });
                }}
                format={formatForTimePicker}
                style={{
                  width: 150,
                  marginTop: "1.5vh",
                }}
              />
            </Col>
            <Col span={6}>
              <CustomSelect
                name="repeat"
                onSelect={(e) => {
                  if (e == "Custom") {
                    setFormControlObject((prev) => {
                      return { ...prev, custom: true };
                    });
                    setFormControlObject((prev) => {
                      return {
                        ...prev,
                        customObject: {
                          ...prev.customObject,
                          repeatEvery: 1,
                        },
                      };
                    });

                    setFormControlObject((prev) => {
                      return { ...prev, repeat: 1 };
                    });
                  } else {
                    setFormControlObject((prev) => {
                      return { ...prev, custom: false };
                    });
                  }
                }}
                getFieldDecorator={getFieldDecorator}
                values={[
                  { value: "Daily", label: "Daily" },
                  { value: "false", label: "Doesn't Repeat" },
                  { value: "Custom", label: "Custom" },
                ]}
                defaultValue="false"
                required
                style={{ width: 120, marginTop: "1.5vh" }}
              />
              
            </Col>
          </Row>
          {formControlObject.custom ? (
            <>
              <Col md={24}>
                <label style={{ marginLeft: "1.3vw" }}>Repeat Every :</label>

                <InputNumber
                  onChange={(e) => {
                    setFormControlObject((prev) => {
                      return { ...prev, repeat: e };
                    });
                    setFormControlObject((prev) => {
                      return {
                        ...prev,
                        customObject: {
                          ...prev.customObject,
                          repeatEvery: e,
                        },
                      };
                    });
                  }}
                  style={{
                    width: 100,
                    marginRight: "1vw",
                    marginLeft: "1vw",
                  }}
                  min={1}
                  max={formControlObject.range}
                  value={formControlObject.repeat}
                />
                <Cascader
                  style={{ width: 150 }}
                  options={options}
                  placeholder="Please select"
                  onChange={(e) => {
                    setFormControlObject((prev) => {
                      return {
                        ...prev,
                        customObject: {
                          ...prev.customObject,
                          Day: undefined,
                          Month: undefined,
                          Hour: undefined,
                          Minute: undefined,
                          [e[0]]: true,
                        },
                      };
                    });
                    handleRange(e);
                  }}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formControlObject.customObject.Day ? (
            <Row>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  options={checkoptions}
                  style={{
                   
                    marginTop: "2.5vh",
                    marginBottom: "2vh",
                  }}
                  onChange={(e) =>
                    setFormControlObject((prev) => {
                      return {
                        ...prev,
                        customObject: {
                          ...prev.customObject,
                          Day: e,
                        },
                      };
                    })
                  }
                />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Col md={24} style={{ marginTop: "1vh" }}>
            <CustomSelect
              name="timeout"
              label="Timeout"
              getFieldDecorator={getFieldDecorator}
              values={[
                { value: "True", label: "True" },
                { value: "False", label: "False" },
              ]}
              defaultValue={
                formControlObject.data ? formControlObject.data.timeout : ""
              }
              required
            />
          </Col>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
const Wrapped = Form.create()(JobsAddModal);
export default connector(Wrapped);
