import React from "react";
import { Link } from "react-router-dom";

// export default function({ collapsed, styling }) {
export default function({ collapsed }) {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <Link to="/jobs">
          <img
            src="/images/logomark.png"
            alt="Propelld"
            style={{ height: 35 }}
            height={35}
          />
        </Link>
      ) : (
        <Link to="/jobs">
          <img src="/images/logo_new.png" alt="Propelld" style={{ height: 28 }} height={28} />
        </Link>
      )}
    </div>
  );
}
