import { session as _config, apiGateway } from "../config/service";
import { getRequest, postRequest } from "./_helper";

class SessionService {
	static login = (credentials) => {
		return postRequest(apiGateway.baseUrl, `api-gateway/console/agent/login`, credentials);
	};

	static logout = () => {
		return getRequest(apiGateway.baseUrl, `api-gateway/console/logout`, true);
	};

	static fetchLoggedInAgent = () => {
		return getRequest(apiGateway.baseUrl, `api-gateway/console/profile`, true);
	};
}

export default SessionService;
