import React from "react";
import {
	Route,
	Redirect,
	Switch,
	BrowserRouter as Router
} from "react-router-dom";
import { connect } from "react-redux";
import asyncComponent from "./helpers/AsyncFunc";
import App from "./containers/App/App";
import "./styles/styles.less";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);

const PublicRoutes = ({ isLoggedIn }) => {
	return (
		<Router>
			<Switch>
				<Route
					exact
					path={"/"}
					component={asyncComponent(() =>
						import("./containers/Pages/signin")
					)}
				/>
				<Route
					exact
					path={"/404"}
					component={asyncComponent(() =>
						import("./containers/Pages/404")
					)}
				/>
				<Route
					exact
					path={"/500"}
					component={asyncComponent(() =>
						import("./containers/Pages/500")
					)}
				/>
				<Route
					exact
					path={"/signin"}
					component={asyncComponent(() =>
						import("./containers/Pages/signin")
					)}
				/>
				<Route
					exact
					path={"/forgotpassword"}
					component={asyncComponent(() =>
						import("./containers/Pages/forgotPassword")
					)}
				/>
				<Route
					exact
					path={"/resetpassword"}
					component={asyncComponent(() =>
						import("./containers/Pages/resetPassword")
					)}
				/>
				<RestrictedRoute
					path="/"
					component={App}
					isLoggedIn={isLoggedIn}
				/>
				<Route
					component={asyncComponent(() =>
						import("./containers/Pages/404")
					)}
				/>
			</Switch>
		</Router>
	);
};

const mapStateToProps = state => {
	const { Auth } = state;
	return {
		isLoggedIn: Auth.isAuthenticated
	};
};

export default connect(mapStateToProps)(PublicRoutes);
