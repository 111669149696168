import { actions as types } from "./action";
import auth from "../../utils/authenticator";
import {
	showSpinner,
	hideSpinner,
	showError,
	addDoneActions,
} from "../_UI/action";
import { apiRequest } from "../Api/action";
import service from "../../services/session";
import { message, notification } from "antd";

export const logInFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === types.LOG_IN_REQUEST) {
			const rq = service.login(action.payload);
			dispatch(apiRequest(rq, types.LOG_IN_SUCCESS, types.LOG_IN_FAILED));

			dispatch(showSpinner());
		}

		if (action.type === types.LOG_IN_SUCCESS) {
			dispatch(hideSpinner());
			auth.login(action.payload.Token);
			dispatch(addDoneActions(types.LOG_IN_SUCCESS));
		}
		if (action.type === types.LOG_IN_FAILED) {
			dispatch(hideSpinner());
			if (action.payload && action.payload.Code) {
				const payload = action.payload;
				let err =
					Array.isArray(payload.Errors) && payload.Errors.length > 0
						? payload.Errors[0]
						: null;

				if (err.Code === 4) {
					dispatch(
						showError(
							"field",
							"Credential Error",
							err.Message || "Wrong password.",
							types.LOG_IN_FAILED,
							{
								field: "password",
							}
						)
					);
					return;
				}
				if (err.Code === 1 || err.Code === 2) {
					dispatch(
						showError(
							"field",
							"Failed Login",
							err.Message ||
								"Failed Login. Something Wrong happened.",
							types.LOG_IN_FAILED,
							{
								field: "password",
							}
						)
					);
					return;
				}
				if (err.Code === 6) {
					dispatch(
						showError(
							"field",
							"Not Authorized Error",
							err.Message || "You are Not Authorised to access.",
							types.LOG_IN_FAILED,
							{
								field: "password",
							}
						)
					);
					return;
				}
				if (err.Code === 5) {
					dispatch(
						showError(
							"field",
							"Email Error",
							err.Message ||
								"Account isn't active. Please verify your email.",
							types.LOG_IN_FAILED,
							{
								field: "email",
							}
						)
					);
					return;
				}
				if (err.Code === 3) {
					dispatch(
						showError(
							"field",
							"Email Error",
							err.Message ||
								"Couldn't find your Propelld Account",
							types.LOG_IN_FAILED,
							{
								field: "email",
							}
						)
					);
					return;
				}

				if (err.Code === 7) {
					dispatch(
						showError(
							"field",
							"Email Error",
							err.Message,
							types.LOG_IN_FAILED,
							{
								field: "email",
							}
						)
					);
					return;
				}
				notification.error({
					message: "Failed to Login!",
					description:
						Array.isArray(err.Errors) && err.Errors.length > 0
							? err.Errors[0].Message
							: "",
				});
			} else {
				notification.error({
					message: "Failed to Login!",
					description:
						Array.isArray(action.payload.Errors) &&
						action.payload.Errors.length > 0
							? action.payload.Errors[0].Message
							: "",
				});
			}
		}
	};

export const fetchLoggedInAgentFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === types.FETCH_LOGGED_IN_AGENT_REQUEST) {
			const rq = service.fetchLoggedInAgent(action.payload);
			dispatch(
				apiRequest(
					rq,
					types.FETCH_LOGGED_IN_AGENT_SUCCESS,
					types.FETCH_LOGGED_IN_AGENT_FAILED
				)
			);

			dispatch(showSpinner());
		}

		if (action.type === types.FETCH_LOGGED_IN_AGENT_SUCCESS) {
			dispatch(hideSpinner());
			dispatch(addDoneActions(types.FETCH_LOGGED_IN_AGENT_SUCCESS));
		}
		if (action.type === types.FETCH_LOGGED_IN_AGENT_FAILED) {
			dispatch(hideSpinner());
		}
	};

export const logoutFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);

		if (action.type === types.LOG_OUT_REQUEST) {
			const rq = service.logout();
			dispatch(
				apiRequest(rq, types.LOG_OUT_SUCCESS, types.LOG_OUT_FAILED)
			);
		}

		if (action.type === types.LOG_OUT_SUCCESS) {
			message.success("You were Logout Successfully!");
			dispatch(hideSpinner());
			auth.logOut();
			dispatch(addDoneActions(types.LOG_OUT_SUCCESS));
		}
		if (action.type === types.LOG_OUT_FAILED) {
			dispatch(hideSpinner());
			auth.logOut();
			dispatch(hideSpinner());
		}
	};
export default [logInFlow, fetchLoggedInAgentFlow, logoutFlow];
