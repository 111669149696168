import JobsScheduler from "./JobScheduler/reducer";
import UI from "./_UI/reducer";
import App from "./App/reducer.js";
import Auth from "./Authentication/reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  UI,
  Auth,
  App,
  JobsScheduler
});

export default rootReducer;
