import api from "./Api/middleware";
import auth from "./Authentication/middleware"
import Jobscheduler from "./JobScheduler/middleware"

const rootmiddleware = [
  ...auth,
  ...api,
  ...Jobscheduler,
];

export default rootmiddleware;
