import { createAction } from "@reduxjs/toolkit";
import { actionRequest } from "../helper";
import {EditJobInterface,ChangeStateInterface,deleteStateInterface} from "./types"

export const actions = {
  FETCH_ALL_JOBS: "FETCH_ALL_JOBS",
  ADD_NEW_JOB: "ADD_NEW_JOB",
  DELETE_JOB: "DELETE_JOB",
  CHANGE_STATE: "CHANGE_STATE",
  EDIT_JOB: "EDIT_JOB",  
};

export const fetchAllJobsRequest = createAction(
  actionRequest(actions.FETCH_ALL_JOBS)
);
export const addNewJob = createAction<EditJobInterface>(
    actionRequest(actions.ADD_NEW_JOB)
);
export const deletejobRequest = createAction<deleteStateInterface>(
  actionRequest(actions.DELETE_JOB)
);
export const changeState = createAction<ChangeStateInterface>(
  actionRequest(actions.CHANGE_STATE)
);
export const editJob = createAction<EditJobInterface>(
    actionRequest(actions.EDIT_JOB)
);
